import axios from 'axios'
import router from '@/router/'
import {inportFun} from '@/components/Mesg/mesgshow'
const service = axios.create({
    // baseURL: 'http://127.0.0.1:8238/api', // 所有的请求地址前缀部分
    // baseURL: 'http://121.43.60.29:8238/api', // 所有的请求地址前缀部分
    baseURL: 'https://admin.dongdumms.com/api', // 所有的请求地址前缀部分
    timeout: 60000, // 请求超时时间毫秒
    withCredentials: true, // 异步请求携带cookie
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    changeOrigin: true,
})
 
// 添加请求拦截器
service.interceptors.request.use(
    function (config) {
      if (sessionStorage.getItem('dongduUser')) {
        config.headers.token = JSON.parse(sessionStorage.getItem('dongduUser')).token
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
)
 
// 添加响应拦截器
service.interceptors.response.use(
    function (response) {
        const dataAxios = response.data
        const {code,msg} = dataAxios
        if(code==5004){
          inportFun({content: dataAxios.msg,errOrSuc: 'err'})
          if(msg!="账户已到期") router.push('/')
          return dataAxios
        }else{
          return dataAxios
        }
    },
    function (error) {
        inportFun({content:'数据链接出错！请检查网络或联系管理员！',errOrSuc: 'err'})
        return Promise.reject(error)
    }
)
export default service