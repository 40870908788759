<template>
 <transition name='trueOrFalse'>
<div class="trueOrFalse" v-if="show">
    <div class="trueOrFalse_impor">
    <div class="trueOrFalse_title">{{title}}</div>
    <div class="trueOrFalse_con">{{content}}</div>
    <div class="trueOrFalse_go"><button type="button" @click="refsh" v-if="errshow">取消</button><button type="button" @click="nextgo" :class="torf?'suc':'err'">确认</button></div>
    </div>
</div>
 </transition>
</template>

<script>
export default {
  name: 'trueOrFalse',
  data () {
    return {
      errshow: true
    }
  },
  created () {

  },
  methods: {
    nextgo () {
      if (this.suc) this.suc()
      this.show = false
      this.hideDom()
    },
    refsh () {
      if (this.err) this.err()
      this.show = false
      this.hideDom()
    }
  }
}

</script>
