<template>
  <div class="list_c_2">
    <ul>
      <li>共{{page.total}}条数据 {{pages}}页</li>
      <li :class="page.pageIndex==1?'numpag dis':'numpag'" @click="pareatNum(1)"><a href="javascript:void(0)">首页</a></li>
      <li class="numpag" @click="pareatNum(page.pageIndex-1)"><a href="javascript:void(0)">上一页</a></li>
      <li v-for="item in pagesNumList" :class="page.pageIndex==item?'numpag hover':'numpag'" :key="item" @click="pareatNum(item)"><a href="javascript:void(0)">{{item}}</a></li>
      <li class="numpag" @click="pareatNum(page.pageIndex+1)"><a href="javascript:void(0)">下一页</a></li>
      <li :class="page.pageIndex==pages?'numpag dis':'numpag'" @click="pareatNum(pages)"><a href="javascript:void(0)">末页</a></li>
      <li class="textpag">每页显示 
        <select v-model="page.pageSize" @change="pareatNum(1)">
          <option :value="10">10</option>
          <option :value="20">20</option>
          <option :value="50">50</option>
        </select> 条</li>
      <li class="textpag">去第<input type="text" v-model="goPageSize" @keypress.enter="pareatNum(goPageSize)">页</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'page',
  watch:{
    "pageData":{
      handler(){
        this.rDom()
      },
      deep:true,
    }
  },
  props:{
    pageData:{
      type:Object,
      default:()=>{
        return{
          pageSize:20,
          pageIndex:1,
          total:0,
        }
      }
    }
  },
  data() {
    return {
      page:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      pages:0,
      goPageSize:null,
      pagesNumList: []
    }
  },
  created() {
    this.rDom()
  },
  components: {},
  mounted() {},
  methods: {
    pareatNum(num) {
      if (num >= 1 && num <= this.pages) {
        this.page.pageIndex = num
        this.rDom()
        this.$emit('resetData', this.page)
      }
    },
    rDom () {
      this.page=this.pageData
      this.pages = Math.ceil(this.pageData.total / this.pageData.pageSize)
      let numTot = this.pages > 5 ? 5 : this.pages
      this.pagesNumList.length = 0
      for (let i = 0; i < numTot; i++) {
        if (this.pages < 6 || this.pageData.pageIndex < 3) {
          this.pagesNumList[i] = i + 1
        } else if (this.pageData.pageIndex > (this.pages - 3)) {
          this.pagesNumList[i] = this.pages - 4 + i
        } else {
          this.pagesNumList[i] = this.pageData.pageIndex - 2 + i
        }
      }
    }
  },
}
</script>
