export function getUser(userName){
  let user=JSON.parse(localStorage.getItem("dongDuUser"))
  if(user&& Array.isArray(user)){
    let thisuser=user.find(e=>e.userName===userName)
    if(thisuser){
      return thisuser
    }else{
      user.push({
        userName,
        navArr:[]
      })
      localStorage.setItem('dongDuUser',JSON.stringify(user))
    }
  }else{
    let userObj=[{
      userName,
      navArr:[]
    }]
    localStorage.setItem('dongDuUser',JSON.stringify(userObj))
  }
  return false
}
export function addUser(userOBJ){
  let user=JSON.parse(localStorage.getItem("dongDuUser"))
  if(user&& Array.isArray(user)){
    let s=user.findIndex(e=>e.userName===userOBJ.userName)
    if(s>=0){
      let obj=JSON.parse(JSON.stringify({...user[s],...userOBJ}))
      user.splice(s,1,obj)
      localStorage.setItem('dongDuUser',JSON.stringify(user))
    }else{
      user.push(userOBJ)
      localStorage.setItem('dongDuUser',JSON.stringify(user))
    }
  }else{
    let userObj=[userOBJ]
    localStorage.setItem('dongDuUser',JSON.stringify(userObj))
  }
}
export function delUser(userName){
  let user=JSON.parse(localStorage.getItem("dongDuUser"))
  if(user&& Array.isArray(user)){
    let s=user.findIndex(e=>e.userName===userName)
    if(s>=0){
      user.splice(s,1)
      localStorage.setItem('dongDuUser',JSON.stringify(user))
    }
  }
}
export function changeStr(str){
  let s=str.split(',')
  return s.join('、')
}

export function deepCopy(obj){
  return JSON.parse(JSON.stringify(obj))
}
export function sortFn(arr,asc){
  arr=[...new Set(arr)]
  if(asc!=='desc') arr=arr.sort((a,b)=>a - b)
  else arr=arr.sort((a,b)=>b - a)
  return arr
}
export function testStr(str){
  str=String(str)
  if(str.trim()==''||str.length==0||str=='null'||str=="undefined") return true
  else return false
}
export function testNumber(num,no){
  if(no){
    if(parseFloat(num)||parseFloat(num)==0) return false
    else return true
  }else{
    if(parseFloat(num)) return false
    else return true
  }
}
export function replaceNum(str){
  str=String(str)
  str=str.replace(/[^\d.]/g,'')
  return str
}