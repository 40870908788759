<template>
  <div class="center_b" :style="`width:${width}px`" ref="rightW">
    <div class="b_auto">
      <div class="center_b_1">
        <ul>
          <!-- <li @click="lockWidth"><span class="gdfd"></span></li> -->
          <li @click="closedWindow"><span class="closed"></span></li>
        </ul>
        <h2>{{title}}</h2>
      </div>
      <slot></slot>
      <div class="center_b_lr" @mousedown.prevent="startMove"></div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex"
export default {
  name: 'rightWindow',
  props:{
    title:{
      type:String,
      default:'无标题'
    }
  },
  data() {
    return {
      max:false,
      moveX:0,
      moveOn:false,
      width:this.$store.state.rightWindowWidth,
    }
  },
  created() {},
  components: {},
  mounted() {
    document.body.onmousemove=this.moveMove
    document.body.onmouseup=this.endMove
  },
  methods: {
    ...mapMutations(['addRightWindowWidth']),
    startMove(e){
      this.moveX=e.x
      this.moveOn=true
    },
    moveMove(e){
      if(this.moveOn){
        let l=e.x-this.moveX
        if(this.width-l<400){
          this.width=400
        }else this.width-=l
        this.moveX=e.x
      }
    },
    endMove(){
      this.addRightWindowWidth(this.width)
      this.moveOn=false
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
  },
  unmounted(){
    document.body.onmousemove=null
    document.body.onmouseup=null
  }
}
</script>
