import {createApp} from 'vue'
import MesgDom from './mesg.vue'
import trueOrFalseDom from './trueOrFalse.vue'


export const inportFun=function(options){
  let dom =document.createElement("div")
  document.body.appendChild(dom)
  let mesg= createApp(MesgDom,{
    ...options,
    hideDom(){
      mesg.unmount(dom)
      document.body.removeChild(dom)
    }
  })
  return mesg.mount(dom)
}

const Mesg = (app)=>{
  app.config.globalProperties.$Mesg = inportFun
}


const trueOrFalse = {
  install:(app)=>{
    app.config.globalProperties.$trueOrFalse = function(options){
      let op={
        show: true,
        title: '',
        content: '',
        torf: true,
        suc: '',
        err: '',
      }
      let dom =document.createElement("div")
      document.body.appendChild(dom)
      let trueOr= createApp(trueOrFalseDom)
      trueOr.mixin({
        data(){
          return {...op,...options}
        },
        methods:{
          hideDom(){
            trueOr.unmount(dom)
            document.body.removeChild(dom)
          }
        }
      })
      return trueOr.mount(dom)
    }
  }
}
export default {Mesg,trueOrFalse}
