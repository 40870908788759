<template>
  <div class="main">
    <div class="header">
      <div class="header_logo">
        <!-- 东都MMS系统 -->
        <img src="@/assets/images/logo.png" width="325" height="auto" />
      </div>
      <div class="header_1">
        <span>{{$store.state.User.xingming}}{{$store.state.User.xingbie=="男"?"先生":$store.state.User.xingbie=="女"?"女士":''}}&nbsp;&nbsp;您好，今天是{{date}}</span><span>{{dateWeek}}</span>
        <span v-if="stopDay>0">您所属门店 {{$store.state.User.fenpeimendianName}}<img src="@/assets/images/vip.png">还有{{stopDay}}天到期</span><span v-else style="color:red">您所属门店<img src="@/assets/images/vip.png">已经到期</span><a href="javascript:void(0)" @click="openWin">{{stopDay>=0?'续费':'开通'}}</a>
      </div>
      <ul class="header_2">
        <li><a href="javascript:void(0)" title="首页" @click="goUrl('/home','主页')"><img src="@/assets/images/head_right_1.png" width="20" height="20" /></a></li>
        <li><a href="javascript:void(0)" title="设置模版" v-if="$store.state.User.jibieId==0" @click="temSettingShow=true"><span class="icon-copy"></span></a></li>
        <li><a href="javascript:void(0)" title="设置手册" @click="openBZ" class="szsc"><img src="@/assets/images/excel.png" width="20" height="20" /></a></li>
        <li><a href="javascript:void(0)" @click.stop="manMes()"><img src="@/assets/images/head_right_2.png" width="20" height="20" /></a>
          <div class="header_2_1" v-show="manMesShow">
            <div class="header_2_1_1" @click="goUrl('/perPor','个人信息')">个人信息</div>
            <div class="header_2_1_1" @click="loginOut">安全退出</div>
          </div>
        </li>
      </ul>
      <div class="clear"></div>
    </div>
    <div class="left_nav">
      <div class="left_auto">
        <ul class="nav_list nav_list_deep" v-if="$store.state.User.admin==9">
          <li><p @click="goUrl('/allUser','账号管理')"><span class="icon-users"></span>账号管理</p></li>
          <li><p @click="goUrl('/allMendian','所有门店')"><span class="icon-users"></span>所有门店</p></li>
          <!-- <li><p @click="goUrl('/MyManage','我的推荐')"><span class="icon-share-2"></span>我的推荐</p></li>
          <li><p @click="goUrl('/exchange','积分兑换')"><span class="icon-shopping-bag"></span>积分兑换</p></li> -->
        </ul>
        <ul class="nav_list">
          <li><p @click="goUrl('/home','主页')"><span class="icon-home"></span>主页</p></li>
          <li v-if="powShowForArr(21,29)"><p @click="tggThisChild"><span class="icon-settings"></span>系统管理</p>
            <ol>
              <li @click="goUrl('/storeManagement','门店管理')" v-if="powShow(2100)">门店管理</li>
              <li @click="goUrl('/productSettings','商品设置')" v-if="powShow(2200)">商品设置</li>
              <li @click="goUrl('/shareSetting','级别设置')" v-if="powShow(2300)">级别设置<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/VIPLVSetting','会员等级设置')" v-if="powShow(2500)">会员等级设置</li>
              <li @click="goUrl('/itemsSetting','消费项目设置')" v-if="powShow(2400)">消费项目设置</li>
              <li @click="goUrl('/packageSettings','套餐设置')" v-if="powShow(2600)">套餐设置</li>
              <li @click="goUrl('/empSetting','员工设置')" v-if="powShow(2700)">员工设置<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/attendanceSetting','考勤设置')" v-if="powShow(2800)">考勤设置<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/OperationRecord','操作记录')" v-if="powShow(2900)">操作记录</li>
              <li @click="goUrl('/orderRecord','订单记录')" v-if="powShow(2900)">订单记录</li>
              <li @click="goUrl('/SMSSendRecord','短信发送记录')" v-if="powShow(2900)">短信发送记录</li>
              <!-- <li @click="goUrl('/backSetting','备份设置')">备份设置</li> -->
            </ol>
          </li>
        </ul>
        <ul class="nav_list nav_list_deep">
          <li v-if="powShow(3100)"><p @click="goUrl('/customerCheckout','结账&挂单')"><span class="icon-slack"></span>结账 & 挂单</p></li>
          <li v-if="powShow(3200)"><p @click="goUrl('/ActivateVIP','会员开卡')"><span class="icon-credit-card"></span>开卡</p></li>
          <li v-if="powShow(3300)"><p @click="goUrl('/rechargeMember','会员充值')"><span class="icon-download"></span>充值</p></li>
          <li v-if="powShow(4200)"><p @click="goUrl('/clockIn','员工考勤')"><span class="icon-user-check"></span>员工考勤<img src="@/assets/images/vip.png"></p></li>
        </ul>
        <ul class="nav_list">
          <li v-if="powShowForArr(31,37)"><p @click="tggThisChild"><span class="icon-users"></span>顾客管理</p>
            <ol>
              <li @click="goUrl('/customerCheckout','结账&挂单')" v-if="powShow(3100)">结账 & 挂单</li>
              <li @click="goUrl('/ActivateVIP','会员开卡')" v-if="powShow(3200)">会员开卡</li>
              <li @click="goUrl('/rechargeMember','会员充值')" v-if="powShow(3300)">会员充值</li>
              <li @click="goUrl('/rechargeRecord','充值记录')" v-if="powShow(3400)">充值记录</li>
              <li @click="goUrl('/consumptionRecords','消费记录')" v-if="powShow(3500)">消费记录</li>
              <li @click="goUrl('/itemCount','消费项目')" v-if="powShow(3700)">消费项目</li>
              <li @click="goUrl('/VIPList','会员列表')" v-if="powShow(3600)">会员列表</li>
            </ol>
          </li>
          <li v-if="powShowForArr(41,44)"><p @click="tggThisChild"><span class="icon-user"></span>员工管理</p>
            <ol>
              <li @click="goUrl('/empSettingThis','本店员工')" v-if="powShow(4100)">本店员工<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/clockIn','员工考勤')" v-if="powShow(4200)">员工考勤<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/clockInRecords','考勤记录')" v-if="powShow(4300)">考勤记录<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/percentageRecord','员工提成')" v-if="powShow(4400)">员工提成<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/wages','员工工资')" v-if="powShow(4500)">员工工资<img src="@/assets/images/vip.png"></li>
            </ol>
          </li>
          <li v-if="powShowForArr(51,57)"><p @click="tggThisChild"><span class="icon-shopping-cart"></span>商品管理</p>
            <ol>
              <li @click="goUrl('/inventoryIn','商品采购')" v-if="powShow(5100)">商品采购<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/inventoryOut','采购退货')" v-if="powShow(5200)">采购退货<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/checkInv','商品盘点')" v-if="powShow(5300)">商品盘点<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/inventoryInRecord','采购记录')" v-if="powShow(5400)">采购记录<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/inventoryOutRecord','退货记录')" v-if="powShow(5500)">退货记录<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/checkRecord','盘点记录')" v-if="powShow(5600)">盘点记录<img src="@/assets/images/vip.png"></li>
              <li @click="goUrl('/stock','商品库存')" v-if="powShow(5700)">商品库存<img src="@/assets/images/vip.png"></li>
            </ol>
          </li>
          <li v-if="powShowForArr(61,64)"><p @click="tggThisChild"><span class="icon-activity"></span>数据统计</p>
            <ol>
              <li @click="goUrl('/tradeBill','营业账目')" v-if="powShow(6100)">营业账目</li>
              <li @click="goUrl('/dailyIncome','日常收入')" v-if="powShow(6200)">日常收入</li>
              <li @click="goUrl('/dailyPay','日常支出')" v-if="powShow(6300)">日常支出</li>
              <li @click="goUrl('/analysis','统计分析')" v-if="powShow(6400)">统计分析<img src="@/assets/images/vip.png"></li>
            </ol>
          </li>

        </ul>
      </div>
    </div>
    <div class="allViews">
      <div class="ViewsNav" v-if="navShow">
        <ul>
          <li @click="$router.push(item.path)" :class="$route.path===item.path?'hover':''" v-for="item in navArr" :key="item.path">{{item.name}}<span class="icon-x closd" @click.stop="delNav(item)"></span></li>
        </ul>
      </div>
      <router-view/>
    </div>
    <Pay v-if="payShow" @closdMegAndRF="payShow=false;" @closdMeg="payShow=false" :Store="Store" :itemName="itemName" :SonId="SonId"></Pay>
    <templateSettings v-if="temSettingShow" @closdMeg="closdTemplateSettings()"></templateSettings>
  </div>
</template>

<script>
import axios from '@/http/axios'
import '@/assets/css/style.css'
import '@/assets/icomoon/style.css'
import { mapState , mapMutations } from "vuex"
import {getUser,delUser} from '@/utils/util'
import {dayList} from '@/data/data'
import moment from 'moment'
import Pay from '@/components/pay'
import templateSettings from '@/components/templateSettings'
export default {
  data(){
    return{
      manMesShow:false,
      navShow:true,
      temSettingShow:false,
      date:'',
      dateWeek:'',
      stopDay:0,
      Store:{},
      payShow:false,
      itemName:"Activate",
      SonId:0,
    }
  },
  computed:{
    ...mapState(['navArr']),
    powShow(){
      return (pow)=>{
        if(this.$store.state.User){
          if(this.$store.state.User.jibieId==0||(this.$store.state.User.pow&&this.$store.state.User.pow.indexOf(pow)>=0)){
            return true
          }else return false
        }else return false
      }
    },
    powShowForArr(){
      return (a,b)=>{
        if(this.$store.state.User){
          if(this.$store.state.User.jibieId==0){
            return true
          }else if(this.$store.state.User.pow){
            for(let i=a;i<=b;i++){
              if(this.$store.state.User.pow.indexOf(i+'00')>=0) return true
            }
            return false
          }else return false
        }else return false
      }
    }
  },
  created(){
    let user=JSON.parse(sessionStorage.getItem('dongduUser'))
    if(user){
      let nav=getUser(user.userName)
      if(nav) this.initNav(nav.navArr)
      this.addVueXUser(user.user)
      this.addDate(user.user.date,user.user.daoqishijian)
    }
  },
  components:{Pay,templateSettings},
  mounted(){
    window.addEventListener('click',()=>{
      if(this.manMesShow) this.manMesShow=false
    })
  },
  methods:{
    ...mapMutations(['addNav','removeNav','initNav','addVueXUser','clearUser']),
    closdTemplateSettings(){
      this.$Mesg({content:'可点击右上角第二个图标重新打开本模本设置窗口'})
      this.temSettingShow=false
    },
    openWin(){
      let {fenpeimendianId,fenpeimendianName}=JSON.parse(sessionStorage.getItem('dongduUser')).user
      this.SonId=this.stopDay>=0?22:21
      this.itemName="Activate"
      this.Store={
        id:fenpeimendianId,
        storeName:fenpeimendianName
      }
      this.payShow=true
    },
    openBZ(){
      window.open("https://www.dongdumms.com/shezhi/","_blank")
    },
    manMes(){
      this.manMesShow=!this.manMesShow
    },
    loginOut(){
      this.$trueOrFalse({
        title: '安全退出',
        content: '您确定要安全退出登录？安全退出将删除您在本机所有登录信息',
        torf: true,
        suc: ()=>{
          sessionStorage.removeItem("dongduUser")
          delUser(this.$store.state.User.userName)
          this.clearUser()
          this.$router.push('/')
        },
      })
    },
    addDate(date,day){
      this.date=date
      this.dateWeek=dayList[moment(date).day()]
      this.stopDay=day
      if(this.$store.state.User.jibieId==0)this.getTemSetting()
    },
    getTemSetting(){
      axios.post('/getTemSetting').then((res) => {
        if(res.code===5000){
          if(res.data){
            this.temSettingShow=true
          }
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    tggThisChild(a){
      let dom=a.target.nodeName=="SPAN"?a.target.parentNode:a.target
      $(dom).next('ol').toggle(200)
    },
    delNav(nav){
      this.removeNav(nav)
    },
    goUrl(url,name){
      let pa={
        name,
        path:url,
      }
      this.addNav(pa)
      this.$router.push(url)
    }
  }
}
</script>
