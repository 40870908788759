<template>
<div class="meg_all">
  <div class="meg_noBottom">
    <h1>应用模版设置</h1>
    <div class="meg_a">
      <div class="meg_a_1">
        <div class="meg_temStting">
          <ul>
            <li v-for="item in dataList" :key="item.type">
              <h3>{{item.name}}</h3>
              <p>{{item.info1}}</p>
              <p>{{item.info2}}</p>
              <p>{{item.info3}}</p>
              <p v-html="item.info4"></p>
              <p>{{item.info5}}</p>
              <p>{{item.info6}}</p>
              <p class="going" @click="createTemplate(item.type)">应用本模版&gt;</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="meg_cld" @click="closdMeg()"><i class="icon-x"></i></div>
  </div>
</div>
</template>

<script>
import axios from '@/http/axios'
export default {
  name: 'templateSettings',
  props: {},
  data () {
    return {
      dataList:[{
        type:'lifadian',
        name:"美发店模版",
        info1:"商品：护发素(99元)，洗发露(199元)",
        info2:"级别：店长、技术总监、首席设计师、烫染师、洗护师",
        info3:"会员等级：黄金(8折)、白金(7折)、钻石会员(6折)",
        info4:"消费项目：洗剪吹类（门店剪发:38元，黄金会员30元，白金25元，钻石20元），烫染护类（门店染发：188元走会员折扣、烫发：268元走会员折扣），<br>　　　　　网络团购类（网络19.9剪发），活动类（精品接发1800元，会员统一1580元）；",
        info5:"充值套餐：黄金会员套餐(￥200)、白金会员套餐(￥500)、钻石会员套餐(￥1000)",
        info6:"考勤规则：9:30-20:00"
      }]
    }
  },
  created () {
    this.init()
  },
  computed:{
  },
  mounted () {},
  components: {},
  methods: {
    init(){
    },
    createTemplate(type){
      this.$trueOrFalse({
        title: '信息提示',
        content: '模版设置为大数据统计各门店后综合设置，并不一定能完全适应您的门店，应用模版设置后，可自行查看、修改、删除各项设置，！',
        torf: true,
        suc:()=>{
          axios.post('/store/createTemplate',{type}).then((res) => {
            if (res.code === 5000) {
              this.$Mesg({ content:'应用成功',})
              this.closdMeg()
            } else {
              this.$Mesg({ content: res.msg, errOrSuc: 'err' })
            }
          })
        }
      })
    },
    closdMeg () {
      this.$emit('closdMeg')
    },
  }
}
</script>
