export const productUnit = ["瓶", "箱", "包", "盒", "桶", "条", "根"]
export const defOption = {
  title: { show: false },
  legend: {
    textStyle: {
      textBorderColor: "#FFF",
      textBorderWidth:3,
    },
    top: 1,
  },
  grid: {
    left: 50,
    top: 50,
    bottom: 30,
    right: 20,
  },
  tooltip: {},
  xAxis: {
    type: 'category',
    axisLine:{
      lineStyle:{
        color:'#bbb'
      },
    },
    axisTick:{
      lineStyle:{
        color:'#bbb'
      },
    },
  },
  yAxis: {
    axisLine: {
      show: true,
      lineStyle:{
        color:'#bbb'
      },
    },
    axisTick: {
      show: true,
      lineStyle:{
        color:'#bbb'
      },
    },
  },
}
export const Series = {
  smooth: 0.4,
  barMaxWidth:30,
  label: {
    show: true,
    backgroundColor: "rgba(255,255,255,0.6)",
    padding: [2, 5],
    distance: 2,
    position: 'top',
  },
  labelLayout: {
    moveOverlap: 'shiftY'
  },
  emphasis: {
    disabled: true
  }
}
export const zffsList = [
  {
    id: 2,
    name: "微信"
  },
  {
    id: 3,
    name: "支付宝"
  },
  {
    id: 4,
    name: "现金"
  },
  {
    id: 5,
    name: "抖音"
  },
  {
    id: 6,
    name: "美团"
  },
  {
    id: 7,
    name: "饿了么"
  },
  {
    id: 8,
    name: "携程"
  },
  {
    id: 9,
    name: "百度"
  },
  {
    id: 10,
    name: "淘宝"
  },
  {
    id: 11,
    name: "京东"
  },
  {
    id: 12,
    name: "拼多多"
  },
  {
    id: 99,
    name: "其他"
  },
]
export const dayList = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
export const exchangeFLList = [{id:1,name:"本系统相关"},{id:2,name:"日常用品"}]
export const disGradeList = [{id:0,name:"向上取整"},{id:1,name:"向下取整"},{id:2,name:"四舍五入"},{id:3,name:"不取整"}]
export const NavPowList = [
  {
    id: '2',
    name: '系统管理',
    select: [],
    sonNav: [
      {
        id: '21',
        name: '门店管理',
        select: [],
        pow: [
          {
            id: '2100',
            name: '查看'
          },
          {
            id: '2101',
            name: '新增'
          },
          {
            id: '2102',
            name: '修改'
          },
          // {
          //   id: '2103',
          //   name: '删除'
          // },
        ]
      },
      {
        id: '22',
        name: '商品设置',
        select: [],
        pow: [
          {
            id: '2200',
            name: '查看'
          },
          {
            id: '2201',
            name: '新增'
          },
          {
            id: '2202',
            name: '修改'
          },
          {
            id: '2203',
            name: '删除'
          },
        ]
      },
      {
        id: '23',
        name: '级别设置',
        select: [],
        pow: [
          {
            id: '2300',
            name: '查看'
          },
          {
            id: '2301',
            name: '新增'
          },
          {
            id: '2302',
            name: '修改'
          },
          {
            id: '2303',
            name: '删除'
          },
        ]
      },
      {
        id: '25',
        name: '会员等级设置',
        select: [],
        pow: [
          {
            id: '2500',
            name: '查看'
          },
          {
            id: '2501',
            name: '新增'
          },
          {
            id: '2502',
            name: '修改'
          },
          {
            id: '2503',
            name: '删除'
          },
        ]
      },
      {
        id: '24',
        name: '消费项目设置',
        select: [],
        pow: [
          {
            id: '2400',
            name: '查看'
          },
          {
            id: '2401',
            name: '新增'
          },
          {
            id: '2402',
            name: '修改'
          },
          {
            id: '2403',
            name: '删除'
          },
        ]
      },
      {
        id: '26',
        name: '套餐设置',
        select: [],
        pow: [
          {
            id: '2600',
            name: '查看'
          },
          {
            id: '2601',
            name: '新增'
          },
          {
            id: '2602',
            name: '修改'
          },
          {
            id: '2603',
            name: '删除'
          },
        ]
      },
      {
        id: '27',
        name: '员工设置',
        select: [],
        pow: [
          {
            id: '2700',
            name: '查看'
          },
          {
            id: '2701',
            name: '新增'
          },
          {
            id: '2702',
            name: '修改'
          },
          {
            id: '2703',
            name: '删除'
          },
        ]
      },
      {
        id: '28',
        name: '考勤设置',
        select: [],
        pow: [
          {
            id: '2800',
            name: '查看'
          },
          {
            id: '2801',
            name: '新增'
          },
          {
            id: '2802',
            name: '修改'
          },
          {
            id: '2803',
            name: '删除'
          },
        ]
      },
      {
        id: '29',
        name: '操作记录',
        select: [],
        pow: [
          {
            id: '2900',
            name: '查看'
          }
        ]
      },
      

    ]
  },
  {
    id: '3',
    name: '顾客管理',
    select: [],
    sonNav: [
      {
        id: '31',
        name: '顾客结账',
        select: [],
        pow: [
          {
            id: '3100',
            name: '操作'
          },
        ]
      },
      {
        id: '32',
        name: '会员开卡',
        select: [],
        pow: [
          {
            id: '3200',
            name: '操作'
          },
        ]
      },{
        id: '33',
        name: '会员充值',
        select: [],
        pow: [
          {
            id: '3300',
            name: '操作'
          },
        ]
      },{
        id: '34',
        name: '充值记录',
        select: [],
        pow: [
          {
            id: '3400',
            name: '查看'
          },
          {
            id: '3401',
            name: '撤销'
          },
        ]
      },{
        id: '35',
        name: '消费记录',
        select: [],
        pow: [
          {
            id: '3500',
            name: '查看'
          },
          {
            id: '3501',
            name: '撤销'
          },
        ]
      },{
        id: '37',
        name: '消费项目',
        select: [],
        pow: [
          {
            id: '3700',
            name: '查看'
          }
        ]
      },{
        id: '36',
        name: '会员列表',
        select: [],
        pow: [
          {
            id: '3600',
            name: '查看'
          },
          {
            id: '3601',
            name: '修改'
          },
          {
            id: '3602',
            name: '退卡'
          },
        ]
      }
    ]
  },
  {
    id: '4',
    name: '员工管理',
    select: [],
    sonNav: [
      {
        id: '41',
        name: '本店员工',
        select: [],
        pow: [
          {
            id: '4100',
            name: '查看'
          },
          {
            id: '4101',
            name: '新增'
          },
          {
            id: '4102',
            name: '修改'
          },
          {
            id: '4103',
            name: '删除'
          },
        ]
      },
      {
        id: '42',
        name: '员工考勤',
        select: [],
        pow: [
          {
            id: '4200',
            name: '操作'
          },
        ]
      },{
        id: '43',
        name: '考勤记录',
        select: [],
        pow: [
          {
            id: '4300',
            name: '查看'
          },
          {
            id: '4301',
            name: '撤销'
          },
          {
            id: '4302',
            name: '统计'
          },
        ]
      },{
        id: '44',
        name: '员工提成',
        select: [],
        pow: [
          {
            id: '4400',
            name: '查看'
          },
          {
            id: '4401',
            name: '统计'
          },
        ]
      },{
        id: '45',
        name: '员工工资',
        select: [],
        pow: [
          {
            id: '4500',
            name: '查看'
          },
        ]
      }
    ]
  },
  {
    id: '5',
    name: '商品管理',
    select: [],
    sonNav: [
      {
        id: '51',
        name: '商品采购',
        select: [],
        pow: [
          {
            id: '5100',
            name: '操作'
          },
        ]
      },
      {
        id: '52',
        name: '采购退货',
        select: [],
        pow: [
          {
            id: '5200',
            name: '操作'
          },
        ]
      },{
        id: '53',
        name: '商品盘点',
        select: [],
        pow: [
          {
            id: '5300',
            name: '操作'
          },
        ]
      },{
        id: '54',
        name: '采购记录',
        select: [],
        pow: [
          {
            id: '5400',
            name: '查看'
          },
          {
            id: '5401',
            name: '撤销'
          },
        ]
      },{
        id: '55',
        name: '退货记录',
        select: [],
        pow: [
          {
            id: '5500',
            name: '查看'
          },
          {
            id: '5501',
            name: '撤销'
          },
        ]
      },{
        id: '56',
        name: '盘点记录',
        select: [],
        pow: [
          {
            id: '5600',
            name: '查看'
          },
        ]
      },{
        id: '57',
        name: '商品库存',
        select: [],
        pow: [
          {
            id: '5700',
            name: '查看'
          },
        ]
      }
    ]
  },
  {
    id: '6',
    name: '数据统计',
    select: [],
    sonNav: [
      {
        id: '61',
        name: '营业账目',
        select: [],
        pow: [
          {
            id: '6100',
            name: '查看'
          },
        ]
      },
      {
        id: '62',
        name: '日常收入',
        select: [],
        pow: [
          {
            id: '6200',
            name: '查看'
          },
          {
            id: '6201',
            name: '新增'
          },
          {
            id: '6202',
            name: '撤销'
          },
        ]
      },{
        id: '63',
        name: '日常支出',
        select: [],
        pow: [
          {
            id: '6300',
            name: '查看'
          },
          {
            id: '6301',
            name: '新增'
          },
          {
            id: '6302',
            name: '撤销'
          },
        ]
      },{
        id: '64',
        name: '统计分析',
        select: [],
        pow: [
          {
            id: '6400',
            name: '查看'
          }
        ]
      }
    ]
  },
]
export const payData = {
  SMS:{
    id:1,
    text:'SMS',
    name:"短信充值",
    info:"短信发送条数由运营商按字数统计，一般70个汉字为一条，如字数较多，可能会一次发送2条，请知悉。",
    son:[
      {
        id:11,
        name:'100条/10元',
        num:100,
        cny:1000,
      },
      {
        id:12,
        name:'200条/20元',
        num:200,
        cny:2000,
      },
      {
        id:13,
        name:'510条/50元',
        num:510,
        cny:5000,
      },
      {
        id:14,
        name:'1050条/100元',
        num:1050,
        cny:10000,
      },
      {
        id:15,
        name:'2200条/200元',
        num:2200,
        cny:20000,
      },
      {
        id:16,
        name:'5800条/500元',
        num:5800,
        cny:50000,
      },
      {
        id:17,
        name:'12000条/1000元',
        num:12000,
        cny:100000,
      }
    ]
  },
  Activate:{
    id:2,
    text:'Activate',
    name:"门店VIP开通/续费/分店",
    info:"VIP门店员工共享上限=VIP门店数量×10,VIP门店数量大于5时，不再设置员工上限，请知悉。",
    son:[
      {
        id:21,
        name:'门店VIP开通',
        yuan:999,
        cny:49900,
      },
      {
        id:22,
        name:'门店VIP续费',
        yuan:999,
        cny:49900,
      },
      {
        id:23,
        name:'门店VIP分店',
        yuan:999,
        cny:49900,
      },
    ]
  },
}
export const tongzhileixing = [
  {
    id: 1,
    name: "短信通知"
  },
  {
    id: 2,
    name: "公众号通知"
  },
  {
    id: 3,
    name: "短信+公众号通知"
  },
]
export const AdminList = [
  {
    id: 0,
    name: "正式账号"
  },
  {
    id: 1,
    name: "正式账号"
  },
  {
    id: 2,
    name: "正式账号"
  },
  {
    id: 3,
    name: "正式账号"
  },
  {
    id: 4,
    name: "正式账号"
  },
  {
    id: 9,
    name: "超管账号"
  },
]
