<template>
<div class="meg_all">
  <div class="meg_noBottom">
    <h1>自助服务</h1>
    <div class="meg_a">
      <div class="meg_a_1">
        <div class="pay_a">
          <p><span>当前账号：</span>{{User.user.xingming}}</p>
          <p><span>操作门店：</span>{{Store.storeName}}</p>
          <p><span>付款内容：</span>{{actData.name}}</p>
          <p class="info">{{actData.info}}</p>
        </div>
        <div class="pay_title">选项</div>
        <div class="pay_b">
          <p v-for="item in actData.son" :class="getItemClass(item.id)" :key="item.id" @click="addItem(item)">{{item.name}}</p>
        </div>
        <div class="pay_title">金额</div>
        <div class="pay_c">
          <p v-if="itemName=='Activate'"><span class="through">原价：{{sonData.yuan}}元/年</span>，现价：<strong>{{sonData.cny/100}}</strong>元/年</p>
          <p>您将支付<strong class="red">{{(sonData.cny/100).toFixed(2)}}</strong>元</p>
        </div>
        <div class="pay_title">付款方式</div>
        <div class="pay_b">
          <p class="pay_wechat on"><img src="@/assets/images/wechat.png">微信支付</p>
        </div>
        <div class="pay_d" @click="goPay">立即支付>></div>
      </div>
    </div>
    <div class="meg_cld" @click="closdMeg()"><i class="icon-x"></i></div>
    <div class="meg_xiaotu" v-if="ewmShow">
      <div class="meg_xiaotu_box">
        <div class="meg_xiaotu_a">
          <img :src="dataURL">
          <p>请微信扫码支付(二维码有效期2个小时)</p>
        </div>
        <div class="meg_xiaotu_b">
          <div class="meg_xiaotu_b_1" @click="getDingdanState()">支付完成</div>
          <div class="meg_xiaotu_b_2" @click="getDingdanState()">关闭</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from '@/http/axios'
import {payData} from '@/data/data'
import qrcode from 'qrcode'
export default {
  name: 'pay',
  props: {
    itemName:{
      type:String,
      default:'SMS'
    },
    disIds:{
      type:Array,
      default:()=>[],
    },
    Store:Object,
    SonId:Number,
  },
  data () {
    return {
      payData,
      actData:{son:[]},
      sonData:{cny:0,yuan:0},
      User:JSON.parse(sessionStorage.getItem('dongduUser')),
      ewmShow:false,
      dataURL:'',
      out_trade_no:null,
    }
  },
  created () {
    this.init()
  },
  computed:{
    getItemClass(){
      return (id)=>{
        let ids=''
        if(this.$store.state.User.daoqishijian>=0){
          if(id==21){ids+='payDis '}
        }else{
          if(id==22){ids+='payDis '}
        }
        if(this.sonData.id==id) ids+='on'
        return ids
      }
    },
  },
  mounted () {},
  components: {},
  methods: {
    init(){
      this.actData=this.payData[this.itemName]
      if(this.SonId) this.sonData=this.actData.son.find(e=>e.id==this.SonId)
    },
    showEWM(url){
      qrcode.toDataURL(url,{
        width:300,
        height:300,
        margin:3
      },(err,baseurl)=>{
        if(err){
          this.$Mesg({content:'二维码生成失败',errOrSuc:'err'})
        }else{
          this.dataURL=baseurl
          this.ewmShow=true
        }
      })
    },
    addItem(item){
      if(this.$store.state.User.daoqishijian>=0){
        if(item.id==21){
          this.$Mesg({content:'您的门店已经是VIP了'})
          return
        }
      }else{
        if(item.id==22){
          this.$Mesg({content:'请您先开通VIP',errOrSuc:'err'})
          return
        }
      }
      this.sonData=item
    },
    getDingdanState(){
      this.ewmShow=false
      axios.post('/pays/getState',{
        out_trade_no:this.out_trade_no
      }).then((res) => {
        if(res.code===5000){
          if(res.data!=1){
            this.$Mesg({content:'操作成功'})
            this.$emit('closdMegAndRF')
          }else{
            this.$Mesg({content:'东都MMS并没有收到充值成功的信息，请稍后在系统管理-订单记录中查询',errOrSuc:'err'})
          }
        }
      })
    },
    goPay(){
      if(this.Store.id&&this.actData.text&&this.sonData.id){
        axios.post('/pays/getURLToImg',{
          store:{id:this.Store.id,name:this.Store.storeName},
          actName:this.actData.text,
          sonId:this.sonData.id
        }).then((res) => {
          if(res.code===5000&&res.data.status==200){
            this.out_trade_no=res.out_trade_no
            this.showEWM(res.data.code_url)
          }
        })
      }else{
        this.$Mesg({content:'请选择充值内容',errOrSuc:'err'})
      }
    },
    closdMeg () {
      this.$emit('closdMeg')
    },
  }
}
</script>
