import { createRouter, createWebHistory } from 'vue-router'
import login from '../views/login.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/packageSettings',
    name: 'packageSettings',
    component: () => import('../views/packageSettings')
  },
  {
    path: '/productSettings',
    name: 'productSettings',
    component: () => import('../views/productSettings')
  },
  {
    path: '/storeManagement',
    name: 'storeManagement',
    component: () => import('../views/storeManagement')
  },
  {
    path: '/empSetting',
    name: 'empSetting',
    component: () => import('../views/empSetting')
  },
  {
    path: '/shareSetting',
    name: 'shareSetting',
    component: () => import('../views/shareSetting')
  },
  {
    path: '/itemsSetting',
    name: 'itemsSetting',
    component: () => import('../views/itemsSetting')
  },
  {
    path: '/backSetting',
    name: 'backSetting',
    component: () => import('../views/backSetting')
  },
  {
    path: '/VIPLVSetting',
    name: 'VIPLVSetting',
    component: () => import('../views/VIPLVSetting')
  },
  {
    path: '/OperationRecord',
    name: 'OperationRecord',
    component: () => import('../views/OperationRecord')
  },
  {
    path: '/ActivateVIP',
    name: 'ActivateVIP',
    component: () => import('../views/ActivateVIP')
  },
  {
    path: '/customerCheckout',
    name: 'customerCheckout',
    component: () => import('../views/customerCheckout')
  },
  {
    path: '/VIPList',
    name: 'VIPList',
    component: () => import('../views/VIPList')
  },
  {
    path: '/rechargeRecord',
    name: 'rechargeRecord',
    component: () => import('../views/VIPList/rechargeRecord')
  },
  {
    path: '/consumptionRecords',
    name: 'consumptionRecords',
    component: () => import('../views/VIPList/consumptionRecords')
  },
  {
    path: '/rechargeMember',
    name: 'rechargeMember',
    component: () => import('../views/rechargeMember/')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail/')
  },
  {
    path: '/empSettingThis',
    name: 'empSettingThis',
    component: () => import('../views/empSettingThis/')
  },
  {
    path: '/attendanceSetting',
    name: 'attendanceSetting',
    component: () => import('../views/attendanceSetting/')
  },
  {
    path: '/clockIn',
    name: 'clockIn',
    component: () => import('../views/clockIn/')
  },
  {
    path: '/clockInRecords',
    name: 'clockInRecords',
    component: () => import('../views/clockInRecords/')
  },
  {
    path: '/percentageRecord',
    name: 'percentageRecord',
    component: () => import('../views/percentageRecord/')
  },
  {
    path: '/inventoryIn',
    name: 'inventoryIn',
    component: () => import('../views/inventoryIn/')
  },
  {
    path: '/inventoryOut',
    name: 'inventoryOut',
    component: () => import('../views/inventoryOut/')
  },
  {
    path: '/checkInv',
    name: 'checkInv',
    component: () => import('../views/checkInv/')
  },
  {
    path: '/inventoryInRecord',
    name: 'inventoryInRecord',
    component: () => import('../views/inventoryInRecord/')
  },
  {
    path: '/inventoryOutRecord',
    name: 'inventoryOutRecord',
    component: () => import('../views/inventoryOutRecord/')
  },
  {
    path: '/checkRecord',
    name: 'checkRecord',
    component: () => import('../views/checkRecord/')
  },
  {
    path: '/stock',
    name: 'stock',
    component: () => import('../views/stock/')
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import('../views/analysis/')
  },
  {
    path: '/dailyPay',
    name: 'dailyPay',
    component: () => import('../views/dailyPay/')
  },
  {
    path: '/dailyIncome',
    name: 'dailyIncome',
    component: () => import('../views/dailyIncome/')
  },
  {
    path: '/tradeBill',
    name: 'tradeBill',
    component: () => import('../views/tradeBill/')
  },
  {
    path: '/perPor',
    name: 'perPor',
    component: () => import('../views/perPor/')
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import('../views/reg/')
  },
  {
    path: '/itemCount',
    name: 'itemCount',
    component: () => import('../views/itemCount/')
  },
  {
    path: '/manage',
    name: 'manage',
    component: () => import('../views/manage/')
  },
  {
    path: '/MyManage',
    name: 'MyManage',
    component: () => import('../views/MyManage/')
  },
  {
    path: '/allUser',
    name: 'allUser',
    component: () => import('../views/allUser/')
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import('../views/exchange/')
  },
  {
    path: '/allMendian',
    name: 'allMendian',
    component: () => import('../views/allMendian/')
  },
  {
    path: '/wages',
    name: 'wages',
    component: () => import('../views/wages/')
  },
  {
    path: '/orderRecord',
    name: 'orderRecord',
    component: () => import('../views/orderRecord/')
  },
  {
    path: '/SMSSendRecord',
    name: 'SMSSendRecord',
    component: () => import('../views/SMSSendRecord/')
  },
  
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
