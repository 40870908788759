<template>
 <transition name='fade'>
    <div :class="errOrSuc?`mes ${errOrSuc}`:'mes suc'"  @mouseover="inThisVue" @mouseout="outThisVue">
        <div class="mes_con"><div class="mes_ico"><span :class="errOrSuc?errOrSuc==='err'?'icon-x':errOrSuc==='suc'?'icon-check':'icon-alert-triangle':'icon-check'"></span></div>{{content}}</div>
    </div>
 </transition>
</template>

<script>
export default {
  name: 'app',
  props:{
    hideDom:Function,
    content:{
      type: String,
      default: "",
    },
    errOrSuc:{
      type: String,
      default: "suc",
    },
    sleep:{
      type: Number,
      default: 5000,
    },
    last:Function,
  },
  data () {
    return {
      showon: true,
      Interval: false,
    }
  },
  created () {
    this.btnClick()
  },
  methods: {
    btnClick () {
      if (this.showon) {
        this.Interval = window.setTimeout(() => {
          if (this.last) this.last()
          this.hideDom()
        }, this.sleep)
      }
    },
    inThisVue () {
      if (this.Interval) {
        window.clearTimeout(this.Interval)
      }
      this.showon = false
    },
    outThisVue () {
      this.showon = true
      this.btnClick()
    }
  }
}

</script>
