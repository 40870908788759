import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import RightWindow from './components/rightWindow'
import Page from './components/page'
import mesgShow from './components/Mesg/mesgshow'
const app = createApp(App)
app.component('RightWindow', RightWindow)
app.component('Page', Page)
app.use(mesgShow.Mesg).use(mesgShow.trueOrFalse)
app.use(store).use(router).mount('#app')


app.directive('pow', {
  mounted (el, binding) {
    let dongdu = JSON.parse(sessionStorage.getItem('dongduUser'))
    if (dongdu && dongdu.user.jibieId != 0) {
      if (dongdu.user.pow) {
        if (dongdu.user.pow.indexOf(binding.arg) < 0) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      } else {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  }
})

