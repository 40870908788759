import { createStore } from 'vuex'
import { addUser } from '@/utils/util'
import router from '@/router'
export default createStore({
  state: {
    navArr:[],
    User:{},
    rightWindowWidth:400,
    parentAdmin:1,
  },
  mutations: {
    addParentAdmin(state,admin){
      state.parentAdmin=admin
    },
    initNav(state,Arr){
      state.navArr=Arr
    },
    addNav(state,nav){
      let s=state.navArr.findIndex(e=>e.path===nav.path)
      if(s===-1){
        state.navArr.push(nav)
        let user=JSON.parse(sessionStorage.getItem('dongduUser'))
        if(user){
          addUser({userName:user.userName,navArr:state.navArr})
        }
        
      }
    },
    removeNav(state,nav){
      let s=state.navArr.findIndex(e=>e.path===nav.path)
      if(s>=0){
        state.navArr.splice(s,1)
        let user=JSON.parse(sessionStorage.getItem('dongduUser'))
        if(user){
          addUser({userName:user.userName,navArr:state.navArr})
        }
        if(window.location.pathname===nav.path){
          let url="/home"
          if(state.navArr.length){
            url=state.navArr[state.navArr.length-1].path
          }
          router.push(url)
        }
      }
    },
    clearNav(state){
      state.navArr=[]
    },
    clearUser(state){
      state.User={}
    },
    addVueXUser(state,Obj){
      state.User=Obj
    },
    addRightWindowWidth(state,width){
      state.rightWindowWidth=width
    },
  },
  actions: {
  },
  modules: {
  }
})
