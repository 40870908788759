<template>
  <div class="login_body">
    <div class="login_impor">
      <div class="login_bt_head">
        <img src="@/assets/images/login_logo.png" />
      </div>
      <div class="login_bt_table">
        <div class="login_bt_table_cell">
          <div class="login_bt_impor">
            <img src="@/assets/images/login_left.jpg" class="login_left">
            <div class="login_bt_from" v-show="showLogin">
              <div class="hspt_header_b">
                <span>Anything For You</span> —— 只为更好的服务
              </div>
              <h1>用户登录</h1>
              <div class="login_bt_from_line">
                <input type="text" v-model="userName" placeholder="请输入用户名" @keypress.enter="loginOn"/>
              </div>
              <div class="login_bt_from_line">
                <input type="password" v-model="passWord" placeholder="请输入密码" @keypress.enter="loginOn"/>
              </div>
              <div class="login_bt_from_line loginButton">
                <button type="button" @click="loginOn" :disabled="loding">登&nbsp;&nbsp;&nbsp;&nbsp;录</button>
              </div>
              <div class="login_bt_from_line errmeg">
                <div class="login_err_meg">{{errMeg}}</div>
              </div>
              <div class="login_bt_from_bottom">
                <a href="https://www.dongdumms.com/priceInfo/" target="_blank"  class="agleft">联系我们</a><a href="javascript:void(0)" @click="$router.push('/reg')">立即注册</a>
              </div>
            </div>
            <div class="login_bt_from" v-show="!showLogin">
              <div class="hspt_header_b">
                <span>Anything For You</span> —— 只为更高效的服务
              </div>
              <h1>用户登录</h1>
              <div class="login_bt_from_line"><p class="login_top_msg">{{phoneMsg}}</p></div>
              <div class="login_bt_from_line">
                <input type="text" placeholder="输入图片内容获取手机验证码" v-model="YZMStr" class="login_yzm_input" maxlength="4" @keyup="testImgYzm"/>
                <a href="javascript:void(0)" id="pic_yzm"></a>
              </div>
              <div class="login_bt_from_line">
                <input type="text" placeholder="请输入手机验证码" class="login_yzm_input" maxlength="6" v-model="phoneYzm" @keypress.enter="loginOnByYzm"/>
                <button type="button" class="yzm_button" :disabled="onGetYzm" @click="getYzm">{{yzmText}}</button>
              </div>
              <div class="login_bt_from_line loginButton">
                <button type="button" @click="loginOnByYzm" :disabled="loding">登&nbsp;&nbsp;&nbsp;&nbsp;录</button>
              </div>
              <div class="login_bt_from_line errmeg">
                <div class="login_err_meg">{{errMeg}}</div>
              </div>
              <div class="login_bt_from_bottom">
                <a href="javascript:void(0)" @click="gobackLogin" class="agleft">返回</a><a href="javascript:void(0)" @click="$router.push('/reg')">立即注册</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GVerify from '@/assets/js/Yzm.js'
import {getUser,testStr,addUser} from '@/utils/util'
import axios from '@/http/axios'
import { mapMutations } from "vuex"
export default {
  name: 'Home',
  data() {
    return {
      Yzm: {},
      userName:'',
      passWord:'',
      YZMStr:'',
      phoneYzm:'',
      loding:false,
      errMeg:'',
      showLogin:true,
      yzmText:'获取验证码',
      onGetYzm:true,
      phoneMsg:'本机首次登录此账号，需短信验证',
      user:{},
    }
  },
  components: {},
  methods:{
    ...mapMutations(['addNav','initNav','addVueXUser']),
    gobackLogin(){
      this.showLogin=true
    },
    getYzm(){
      let phone=this.user.phone
      let reg=/^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if(phone&&!this.onGetYzm&&reg.test(phone)){
        axios.post('/reg/getYzm',{id:this.user.id,userName:this.userName,passWord:this.passWord}).then((res) => {
          if(res.code===5000){
            this.yzmText=300
            this.onGetYzm=true
            this.phoneMsg=`系统已向您的手机：${phone.substring(0,3)+'******'+phone.substring(9,11)}发送短信，5分钟内有效。`,
            this.interval=window.setInterval(() => {
              this.yzmText--
              if(this.yzmText<=0&&this.interval){
                window.clearInterval(this.interval)
                this.yzmText='获取验证码'
                this.onGetYzm=false
              }
            }, 1000);
          }else{
            this.$Mesg({content: res.msg,errOrSuc: 'err'})
          }
        })
      }else{
        this.$Mesg({content: '此账号手机号码不正确，请联系管理员修改手机号码！',errOrSuc: 'err'})
      }
    },
    testImgYzm(){
      if(!testStr(this.YZMStr)&&this.Yzm.validate(this.YZMStr)&&this.yzmText=="获取验证码"){
        this.onGetYzm=false
      }else{
        this.onGetYzm=true
      } 
    },
    loginOnByYzm(){
      this.$Mesg({content:"登录中，请稍后",sleep:1000})
      this.loding=true
      let loginObj={
        userName:this.userName,
        passWord:this.passWord,
        phoneYzm:this.phoneYzm,
      }
      axios.post('/loginByYzm',
        loginObj
      ).then((res) => {
        if(res.code===5000){
          let userObj={
            userName:this.userName,
            token:res.data,
            user:res.user
          }
          addUser({userName:this.userName,keepToken:res.user.keepToken})
          sessionStorage.setItem('dongduUser',JSON.stringify(userObj))
          this.addVueXUser(res.user)
          let user =getUser(this.userName)
          if(user){
            this.initNav(user.navArr)
          }
          if(res.user.daoqishijian<=30&&res.user.daoqishijian>=0){
            this.$Mesg({
              content: `您的所属门店VIP还有${res.user.daoqishijian}天到期，请尽快处理（到期后将失去员工管理、进销存……等等VIP功能）。`,
              errOrSuc: 'war',
            })
          }else if(res.user.daoqishijian<0&&res.mesNum<10){
            this.$Mesg({
              content: `您的所属门店通知短信还有 ${res.mesNum} 条，为不影响您的会员通知功能，请尽快到系统管理-门店管理处为您的门店充值短信。`,
              errOrSuc: 'war',
            })
          }
          this.loding=false
          this.$parent.$parent.addDate(res.user.date,res.user.daoqishijian)
          this.addNav({
            name:'主页',
            path:'/home',
          })
          this.$router.push('/home')
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
        this.loding=false
      }).catch(() => {
        this.loding=false
      })
    },
    loginOn(){
      this.$Mesg({content:"登录中，请稍后",sleep:1000})
      if(testStr(this.userName)){
        this.$Mesg({content:"用户名不能为空！",errOrSuc:"err"})
        return false
      } 
      if(testStr(this.passWord)){
        this.$Mesg({content:"密码不能为空！",errOrSuc:"err"})
        return false
      } 
      this.loding=true
      let user =getUser(this.userName)
      let loginObj={
        userName:this.userName,
        passWord:this.passWord,
      }
      if(user&&user.keepToken){
        loginObj.keepToken=user.keepToken
      }
      axios.post('/login',
        loginObj
      ).then((res) => {
        if(res.code===5000){
          let userObj={
            userName:this.userName,
            token:res.data,
            user:res.user
          }
          addUser({userName:this.userName,keepToken:res.user.keepToken})
          sessionStorage.setItem('dongduUser',JSON.stringify(userObj))
          this.addVueXUser(res.user)
          if(user){
            this.initNav(user.navArr)
          }
          if(res.user.daoqishijian<=30&&res.user.daoqishijian>=0){
            this.$Mesg({
              content: `您的所属门店VIP还有${res.user.daoqishijian}天到期，请尽快处理（到期后将失去员工管理、进销存……等等VIP功能）。`,
              errOrSuc: 'war',
            })
          }else if(res.user.daoqishijian<0&&res.mesNum<10){
            this.$Mesg({
              content: `您的所属门店通知短信还有 ${res.mesNum} 条，为不影响您的会员通知功能，请尽快到系统管理-门店管理处为您的门店充值短信。`,
              errOrSuc: 'war',
            })
          }
          this.loding=false
          this.$parent.$parent.addDate(res.user.date,res.user.daoqishijian)
          this.addNav({
            name:'主页',
            path:'/home',
          })
          this.$router.push('/home')
        }else if(res.code===5001){
          this.user=res.user
          this.showLogin=false
          this.$nextTick(()=>{
            this.initImgYzm()
          })
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
        this.loding=false
      }).catch(() => {
        this.loding=false
      })
    },
    initImgYzm(){
      this.Yzm = new GVerify({
        id: 'pic_yzm',
        width:$('#pic_yzm').width(),
        height:$('#pic_yzm').height(),
      })
    },
  },
  mounted() {},
}
</script>
